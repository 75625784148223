import DateModel from 'app/models/DateModel';

const DAYS_TO_SHOW_WEEKDAY = 5;

export default class MatchKickoffModel {
  kickoff: DateModel;
  relativeDate: DateModel;

  constructor(kickoff: DateModel, relativeDate: DateModel = DateModel.now()) {
    this.kickoff = kickoff;
    this.relativeDate = relativeDate;
  }

  format(): string {
    if (this.kickoff.isSameDay(this.relativeDate)) {
      return this.formatKickoffTime();
    }
    if (this.kickoff.isWithinXDays(DAYS_TO_SHOW_WEEKDAY, this.relativeDate)) {
      return this.kickoff.formatWeekday();
    }
    return this.kickoff.formatShortDate();
  }

  formatKickoffTime(): string {
    return this.kickoff.formatTime();
  }
}
