import {MatchFlag, MatchFlags} from 'app/types/match/MatchFlags';
import {Iso8601DateString} from 'app/types/Iso8601DateString';
import {MatchSection} from 'app/types/match/MatchSection';
import MatchLive from 'app/types/match/MatchLive';
import is from '@sindresorhus/is';
import MatchKickoffModel from 'app/models/match/MatchKickoffModel';
import DateModel from 'app/models/DateModel';

export interface MatchResultModelInterface {
  readonly flags: MatchFlags;
  readonly homeGoal: number | null;
  readonly awayGoal: number | null;
  readonly kickoff?: Iso8601DateString;
  readonly section: MatchSection;
  readonly live?: MatchLive | null;
}

const FULL_PROGRESS = 100;

export class MatchResultModel {
  readonly result: MatchResultModelInterface;
  private readonly kickoff: MatchKickoffModel | null;

  constructor(result: MatchResultModelInterface) {
    this.result = result;
    this.kickoff = result.kickoff ? new MatchKickoffModel(DateModel.fromIso8601(result.kickoff)) : null;
  }

  hasResult(): boolean {
    return is.number(this.result.homeGoal) && is.number(this.result.awayGoal);
  }

  isInFuture(): boolean {
    return this.result.section === MatchSection.Pre;
  }

  isRunning(): boolean {
    return this.result.section === MatchSection.Live;
  }

  isInPast(): boolean {
    return this.result.section === MatchSection.Post;
  }

  isLive(): boolean {
    return this.isRunning() && !!this.result.live && this.hasTicker();
  }

  isRunningWithoutTicker(): boolean {
    return this.isRunning() && !this.hasTicker();
  }

  hasLiveticker(): boolean {
    return this.result.flags?.includes(MatchFlag.Ticker) ?? false;
  }

  hasSoftticker(): boolean {
    return this.result.flags?.includes(MatchFlag.Softticker) ?? false;
  }

  hasTicker(): boolean {
    return this.hasLiveticker() || this.hasSoftticker();
  }

  getLiveProgress(): number {
    if (!this.isLive()) {
      throw new Error('Match is not live');
    }
    const minute = this.result.live!.minute;
    const regular = this.result.live!.regular;
    if (minute >= regular) {
      return FULL_PROGRESS;
    }
    return (minute / regular) * FULL_PROGRESS;
  }

  formatKickoff(): string {
    if (!this.kickoff) {
      throw new Error('Cannot format kickoff without kickoff date');
    }
    return this.kickoff.format();
  }

  isCancelled(): boolean {
    return this.result.flags?.includes(MatchFlag.Cancelled) ?? false;
  }

  hasVerdict(): boolean {
    return this.result.flags?.includes(MatchFlag.Verdict) ?? false;
  }
}
