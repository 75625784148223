import {Iso8601DateString} from 'app/types/Iso8601DateString';
import is from '@sindresorhus/is';
import {captureSentryException} from 'app/services/sentryLogging';

const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;

export default class DateModel {
  date: Date;

  private constructor(timestamp: number) {
    this.date = new Date(timestamp);
  }

  static fromTimestamp(timestamp: number): DateModel {
    if (!is.number(timestamp)) {
      captureSentryException(new Error('DateModel.fromTimestamp: timestamp must be a number'), {extra: {timestamp}});
    }
    return new DateModel(timestamp);
  }

  static fromIso8601(iso8601: Iso8601DateString): DateModel {
    if (!is.string(iso8601)) {
      captureSentryException(new Error('DateModel.fromIso8601: timestamp must be an Iso8601DateString'), {
        extra: {iso8601},
      });
    }
    return new DateModel(Date.parse(iso8601));
  }

  static now(): DateModel {
    return new DateModel(Date.now());
  }

  getRelativeTime(relativeTo: DateModel = new DateModel(Date.now())): string {
    const timestamp = this.toTimestamp();
    const relativeToTs = relativeTo.toTimestamp();
    const diff = relativeToTs - timestamp;
    if (diff < 0) {
      captureSentryException(new Error('To format a relative date, the relative date must be later than the date.'));
      return this.toFullDateString();
    }
    const diff_s = diff / 1000;
    const diff_min = diff_s / 60;
    const diff_h = diff_min / 60;
    const diff_d = diff_h / 24;
    if (diff_min < 5) {
      return 'gerade eben';
    } else if (diff_min < 60) {
      return `vor ${Math.floor(diff_min)} Min`;
    } else if (diff_h < 2) {
      return 'vor 1 Stunde';
    } else if (this.isYesterday(relativeTo)) {
      return 'gestern';
    } else if (diff_h < 24) {
      return `vor ${Math.floor(diff_h)} Stunden`;
    } else if (diff_d < 2) {
      return 'vor 2 Tagen';
    } else if (diff_d < 5) {
      return `vor ${Math.floor(diff_d)} Tagen`;
    } else {
      if (this.date.getFullYear() === relativeTo.date.getFullYear()) {
        return this.toShortDateString();
      }
      return this.toFullDateString();
    }
  }

  formatTime(): string {
    return this.date.toLocaleTimeString('de-DE', {hour: '2-digit', minute: '2-digit'});
  }

  formatWeekday(): string {
    return this.date.toLocaleDateString('de-DE', {weekday: 'short'});
  }

  formatShortDate(): string {
    return this.toShortDateString();
  }

  /**
   * Returns the date in the format 'MMMM YYYY' for example "Januar 2021"
   */
  formatMonthYear(): string {
    return this.date.toLocaleDateString('de-DE', {month: 'long', year: 'numeric'});
  }

  /**
   * Returns the date in the format 'yyyy-MM-dd' for example "2021-01-01"
   */
  formatDateSlug(): string {
    return this.date.toISOString().split('T')[0];
  }

  isToday(): boolean {
    return this.isSameDay(DateModel.now());
  }

  isSameDay(other: DateModel): boolean {
    return this.date.toDateString() === other.date.toDateString();
  }

  isWithinXDays(days: number, other: DateModel): boolean {
    const diff = Math.abs(this.toTimestamp() - other.toTimestamp());
    return diff < days * ONE_DAY_IN_MS;
  }

  private toTimestamp(): number {
    return this.date.getTime();
  }

  /**
   * Returns the date in the format 'DD.MM'
   * @private
   */
  private toShortDateString(): string {
    return this.date.toLocaleDateString('de-DE', {day: '2-digit', month: '2-digit'});
  }

  /**
   * Returns the date in the format 'DD.MM.YYYY'
   * @private
   */
  private toFullDateString(): string {
    return this.date.toLocaleDateString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'});
  }

  private isYesterday(relativeTo: DateModel): boolean {
    const yesterday = new Date(relativeTo.toTimestamp() - ONE_DAY_IN_MS);
    return this.date.toDateString() === yesterday.toDateString();
  }
}
